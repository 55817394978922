


























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FormControlFab extends Vue {
  @Prop({ default: false }) private value!: boolean
  @Prop({ default: false }) private invalid!: boolean
  @Prop({ default: false }) private isLoading!: boolean
  @Prop({ default: true }) private showDeleteAction!: boolean
}
