









import { Component, Prop, Vue } from 'vue-property-decorator'
import IssueForm from '@/main/issues/components/IssueForm.vue'

@Component({
  components: { IssueForm }
})
export default class IssueEditView extends Vue {
  @Prop()
  private issueId!: number

  get issueUri() {
    return `/issues/${this.issueId}`
  }

  redirectToBacklog() {
    this.$router.push({ name: 'ProductBacklog' })
  }
}
