














































import { Component, Prop, Vue } from 'vue-property-decorator'
import 'dayjs/locale/de'
import { Issue } from '@/models/Issue.model'
import { TranslationHelper } from '@/services/translation/TranslationHelper'
import { IssueTransitionHistoryService } from '@/main/issues/services/IssueTransitionHistoryService'
import NoDataAlert from '@/components/shared/alert/NoDataAlert.vue'
import { TransitionHistory } from '@/models/interface/TransitionHistory'

@Component({
  components: { NoDataAlert }
})
export default class IssueTransitionHistory extends Vue {
  @Prop()
  private issue!: Issue | null

  private issueTransitionHistory: TransitionHistory[] = []
  private loadingDone = false

  created() {
    this.$root.$on('issueUpdated', () => {
      this.loadIssueTransitionHistory()
    })
    this.loadIssueTransitionHistory()
  }

  beforeDestroy() {
    this.$root.$off('issueUpdated')
  }

  translateTransition(transition: string) {
    return TranslationHelper.translateTransition(transition)
  }

  async loadIssueTransitionHistory() {
    if (this.issue) {
      this.loadingDone = false
      this.issueTransitionHistory = await IssueTransitionHistoryService.getIssueTransitionHistory(this.issue)
      this.loadingDone = true
    }
  }
}
