import axios, { AxiosResponse } from 'axios'
import { Issue } from '@/models/Issue.model'
import { TransitionHistory } from '@/models/interface/TransitionHistory'

export class IssueTransitionHistoryService {
  static async getIssueTransitionHistory(issue: Issue) {
    const responseIssues = await axios.get<AxiosResponse>('/issue_transition_histories?order[createdAt]=desc', {
      params: {
        issue: issue.iri()
      }
    })
    return IssueTransitionHistoryService.mapIssueTransitionHistories(responseIssues)
  }

  static mapIssueTransitionHistories(response: AxiosResponse): TransitionHistory[] {
    return (response.data as any)['hydra:member'].map((issueTransitionHistoryData: any) => {
      return issueTransitionHistoryData
    })
  }
}
