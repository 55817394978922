var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadingDone)?_c('div',[_c('v-toolbar',{attrs:{"dense":""}},[_c('v-toolbar-title',[_c('v-btn',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Zurück")])],1)],1),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-row',[_c('v-col',[(_vm.editMode)?_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('br'),_c('v-text-field',{attrs:{"label":_vm.$t('issue.create.form.title'),"error-messages":errors[0],"autofocus":"","filled":""},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}})]}}],null,true)}):_c('span',{staticClass:"text-h4",on:{"dblclick":_vm.edit}},[_vm._v(" "+_vm._s(_vm.formData.title)+" ")])],1)],1),_c('v-row',[_c('v-col',[(_vm.editMode)?_c('ValidationProvider',{attrs:{"name":"description","rules":"max:10000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('issue.create.form.description'),"error-messages":errors[0],"filled":"","hint":_vm.formData.description ? _vm.formData.description.length + ' / 10000' : ''},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})]}}],null,true)}):(_vm.formData.description)?_c('span',[_c('vue-markdown',[_vm._v(_vm._s(_vm.formData.description))])],1):_c('span',{staticClass:"font-italic text--secondary"},[_vm._v(" "+_vm._s(_vm.$t('issue.noDescription'))+" ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('issue-meta-information',{attrs:{"issue":_vm.formData}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-container',[_c('v-row',{staticClass:"flex-column",attrs:{"justify":"end"}},[_c('v-col',[_c('issue-enabled-transitions',{model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1),_c('v-col',[_c('issue-assign-to',{model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1),_c('v-col',[(_vm.editMode)?_c('ValidationProvider',{attrs:{"name":"estimation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('issue.estimation.points'),"error-messages":errors[0],"filled":""},model:{value:(_vm.formData.estimation),callback:function ($$v) {_vm.$set(_vm.formData, "estimation", $$v)},expression:"formData.estimation"}})]}}],null,true)}):_c('div',[_c('estimation',{attrs:{"issue":_vm.formData}})],1)],1)],1)],1)],1)],1)],1),_c('form-control-fab',{attrs:{"is-loading":_vm.isLoading,"invalid":invalid},on:{"requestDelete":function($event){_vm.showDeleteConfirm = true},"submit":function($event){return handleSubmit(_vm.save)}},model:{value:(_vm.editMode),callback:function ($$v) {_vm.editMode=$$v},expression:"editMode"}})],1)]}}],null,false,947406098)})],1):_c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"article"}})],1),_c('confirm-dialog',{attrs:{"title":"Löschen","text":_vm.$t('issue.delete.confirmationText')},on:{"confirmed":_vm.deleteIssue},model:{value:(_vm.showDeleteConfirm),callback:function ($$v) {_vm.showDeleteConfirm=$$v},expression:"showDeleteConfirm"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }