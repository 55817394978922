import axios, { AxiosResponse } from 'axios'
import { Issue } from '@/models/Issue.model'
import { IssueHistory } from '@/models/IssueHistory.model'

export class IssueHistoryService {
  static async getIssueHistory(issue: Issue) {
    const responseIssues = await axios.get<AxiosResponse>('/issue_histories?order[createdAt]=desc', {
      params: {
        issue: issue.iri()
      }
    })
    return IssueHistoryService.mapIssueHistories(responseIssues)
  }

  static mapIssueHistories(response: AxiosResponse): IssueHistory[] {
    return (response.data as any)['hydra:member'].map((issueHistoryData: any) => {
      return new IssueHistory(issueHistoryData, issueHistoryData['@id'])
    })
  }
}
