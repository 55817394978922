





































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import { Issue } from '@/models/Issue.model'
import axios, { AxiosResponse } from 'axios'
import IssueEnabledTransitions from '@/main/issues/components/IssueEnabledTransitions.vue'
import IssueTransitionHistory from '@/main/issues/components/IssueTransitionHistory.vue'
import Estimation from '@/main/issues/components/Estimation.vue'
import IssueMetaInformation from '@/main/issues/components/IssueMetaInformation.vue'
import FormControlFab from '@/components/FormControlFab.vue'
import IssueAssignTo from '@/main/issues/components/IssueAssignTo.vue'

@Component({
  components: {
    IssueAssignTo,
    FormControlFab,
    IssueMetaInformation,
    Estimation,
    IssueTransitionHistory,
    IssueEnabledTransitions
  }
})
export default class IssueForm extends Vue {
  $refs!: {
    form: InstanceType<typeof ValidationObserver>
  }

  @Prop() private issueUri!: string
  private formData: Issue | null = null
  private originalIssue: Issue | null = null
  private editMode = false
  private isLoading = false
  private loadingDone = false
  private showDeleteConfirm = false

  async created() {
    this.loadingDone = false
    const response = await axios.get<AxiosResponse>(this.issueUri)
    this.formData = Issue.createFromResponse(response)
    this.originalIssue = Issue.createFromResponse(response)
    this.loadingDone = true
  }

  async save() {
    try {
      this.isLoading = true
      await axios.put(this.issueUri, this.formData)
      this.editMode = false
      this.$root.$emit('issueUpdated', this.formData)
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }

  cancel() {
    this.editMode = false
    this.resetFormData()
  }

  edit() {
    this.editMode = true
  }

  resetFormData() {
    if (this.originalIssue && this.formData) {
      this.formData.title = this.originalIssue.title
      this.formData.description = this.originalIssue.description
    }
  }

  async deleteIssue() {
    try {
      this.isLoading = true
      await axios.delete(this.issueUri)
      this.$emit('issueDeleted', true)
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }
}
