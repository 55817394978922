



























import { Component, Prop, Vue } from 'vue-property-decorator'
import { Issue } from '@/models/Issue.model'
import { TranslationHelper } from '@/services/translation/TranslationHelper'
import { WorkflowService } from '@/services/workflow/WorkflowService'
import { IssueTransition } from '@/models/interface/IssueTransition'

@Component
export default class IssueEnabledTransitions extends Vue {
  @Prop() private value!: Issue
  get issue() {
    return this.value
  }

  private loading = false

  async applyTransition(transition: IssueTransition) {
    const issueUri = this.issue.iri()
    if (!issueUri) {
      return
    }
    this.loading = true
    const response = await WorkflowService.applyTransition(issueUri, transition.name)
    const responseIssue = Issue.createFromResponse(response)
    this.loading = false

    const emitIssue = Issue.createFromIssue(this.issue)
    emitIssue.currentStatus = responseIssue.currentStatus
    emitIssue.enabledTransitions = responseIssue.enabledTransitions
    emitIssue.transitionHistory = responseIssue.transitionHistory
    this.$emit('input', emitIssue)
  }

  translatePlace() {
    return TranslationHelper.translateStatus(this.issue.currentStatus)
  }
  translateTransition(transition: string) {
    return TranslationHelper.translateTransition(transition)
  }
}
