import { AxiosResponse } from 'axios'

export class IssueHistory {
  id?: string
  changeset: any
  createdAt: any
  createdBy: any
  createdByData: any

  constructor(data: any, uri?: string) {
    this.id = uri
    this.changeset = data.changeset
    this.createdAt = data.createdAt
    this.createdBy = data.createdBy
    this.createdByData = data.createdByData
  }

  static createFromResponse(response: AxiosResponse) {
    return new IssueHistory(response.data, response.data['@id'])
  }
}
