



































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Issue } from '@/models/Issue.model'
import { User } from '@/models/User.model'
import axios from 'axios'

@Component
export default class IssueAssignTo extends Vue {
  @Prop() private value!: Issue
  get issue() {
    return this.value
  }

  private availableUsers: User[] = []
  private isLoading = false

  async created() {
    this.isLoading = true
    const users = await axios.get<any>(`/users?productMembers.product=${this.issue.productIri}`)
    users.data['hydra:member'].map((userData: any) => this.availableUsers.push(User.createFromData(userData)))
    this.isLoading = false
  }

  async assign(userIri: string) {
    const response = await axios.put(this.issue.iri(), {
      assignedTo: userIri
    })
    const responseIssue = Issue.createFromResponse(response)

    const emitIssue = Issue.createFromIssue(this.issue)
    emitIssue.assignedToIri = responseIssue.assignedToIri
    this.$emit('input', emitIssue)
  }
}
