






















import { Component, Prop, Vue } from 'vue-property-decorator'
import IssueTransitionHistory from '@/main/issues/components/IssueTransitionHistory.vue'
import IssueHistory from '@/main/issues/components/IssueHistory.vue'
import { Issue } from '@/models/Issue.model'
import TaskListDraggable from '@/main/issues/components/task/TaskListDraggable.vue'

@Component({
  components: { TaskListDraggable, IssueHistory, IssueTransitionHistory }
})
export default class IssueMetaInformation extends Vue {
  @Prop()
  private issue!: Issue | null

  private currentTab = null
}
