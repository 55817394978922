

























































import { Component, Prop, Vue } from 'vue-property-decorator'
import 'dayjs/locale/de'
import { Issue } from '@/models/Issue.model'
import { IssueHistory as IssueHistoryModel } from '@/models/IssueHistory.model'
import { IssueHistoryService } from '@/main/issues/services/IssueHistoryService'
import NoDataAlert from '@/components/shared/alert/NoDataAlert.vue'
@Component({
  components: { NoDataAlert }
})
export default class IssueHistory extends Vue {
  @Prop()
  private issue!: Issue | null

  private issueHistory: IssueHistoryModel[] = []
  private loadingDone = false

  created() {
    this.$root.$on('issueUpdated', () => {
      this.loadIssueHistory()
    })
    this.loadIssueHistory()
  }

  beforeDestroy() {
    this.$root.$off('issueUpdated')
  }

  async loadIssueHistory() {
    if (this.issue) {
      this.loadingDone = false
      this.issueHistory = await IssueHistoryService.getIssueHistory(this.issue)
      this.loadingDone = true
    }
  }
}
